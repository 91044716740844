<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">commitee reports</div>
      </v-row>
      <v-row class="">
        <div class="">
          Committee reports summarize all actions taken and the final
          recommendations made on all matters to be reported to the full county
          assembly for formal consideration.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>

        <v-autocomplete
          prepend-icon="mdi-account-group"
          label="Commitee Name"
          :items="commiteeItems"
          outlined
          no-data-text="There are no commities at the moment"
        ></v-autocomplete>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-row>
        <v-col v-for="key in keyItems" :key="key.title">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ key.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-capitalize">
              {{ key.title }}
            </v-list-item-title>
          </v-list-item>
        </v-col>
      </v-row>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date black--text"
              @click:row="pdfDialog = true"
            >
            </v-data-table>
            <v-dialog v-model="pdfDialog">
              <pdf-viewer />
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../PdfViewer.vue";
export default {
  components: { PdfViewer },
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      pdfDialog: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      commiteeItems: [],
      keyItems: [
        {
          title: "reqular session",
          icon: "mdi-calendar-text",
        },
        {
          title: "adjourned session",
          icon: "mdi-timer-cog-outline",
        },
        {
          title: "special seating",
          icon: "mdi-calendar-star",
        },
        {
          title: "adhock session",
          icon: "mdi-calendar-alert",
        },
      ],
      headers: [
        {
          text: "commitee name",
          value: "name",
          class: "text-uppercase black--text",
        },
        {
          text: "meeting date",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "meeting time",
          value: "time",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          name: "Commitee of the whole",
          date: "Dec 10, 2021",
          time: "7:30 AM",
          type: "emergency",
        },
        {
          name: "Budget Commitee",
          date: "Dec 12, 2021",
          time: "4:30 AM",
          type: "regular",
        },
        {
          name: "Public Health Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          type: "regular",
        },
        {
          name: "Public Safety Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          type: "special",
        },
        {
          name: "Public Transport Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          type: "regular",
        },
        {
          name: "Budget Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          type: "regular",
        },
      ],
    };
  },
  methods: {
    dateIcon(type) {
      if (type === "adjourned") {
        return "mdi-timer-cog-outline";
      } else if (type === "emergency") {
        return "mdi-calendar-alert";
      } else if (type === "special") {
        return "mdi-calendar-star";
      } else {
        return "mdi-calendar-text";
      }
    },
  },
};
</script>

<style></style>
